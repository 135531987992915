<template>
  <div style="font-size: 0.14rem;color: #fff;">
    <div style="padding:0 0.36rem;margin-top: 0.2rem">
      <p style="margin-top: 0.1rem">
        <template v-for="(item, index) in statistics1">
          <span style="white-space:pre-wrap">
            <span>{{ item.label }}</span>
            <span
              style="color: #00F9FF;font-weight: bold;margin-right: 0.56rem">
              {{ item.count }}
            </span>
</span>
        </template>
      </p>
      <p style="margin-top: 0.1rem">
        <template v-for="(item, index) in statistics2">
          <span style="white-space:pre-wrap">
            <span>{{ item.label }}</span>
            <span
              style="color: #00F9FF;font-weight: bold;margin-right: 0.56rem">
              {{ item.count }}
            </span>
</span>
        </template>
      </p>
    </div>
    <div style="padding:0 0.36rem;margin-top: 0.2rem">
      <div style=" height: 1.2rem;width: 100%;">
        <lineCharts :data="latestWeekData" title="最近一周学习人数趋势："></lineCharts>
      </div>
    </div>
  </div>
</template>
<script>
import format from '@/util/timeFormat.js'

export default {
  components: {
    lineCharts: () => import('@/components/dataPart/echarts/line')
  },

  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      statistics1: [
        {label: '日签到人数：', count: '0', code: 'signUpNum'},
        {label: '日人均学习时长：', count: '0', code: 'avgStudyTime'}
      ],

      statistics2: [
        {label: '日完成练习人数：', count: 0, code: 'completeNum'},
        {label: '题库数：', count: 0, code: 'itemBankNum'},
        {label: '文章数：', count: 0, code: 'articleNum'}
      ],
      latestWeekData: []
    }
  },

  watch: {
    data(newData) {
      this.init(newData)
    }
  },
  mounted() {
    this.init(this.data)
  },

  methods: {
    init(data) {
      // data = JSON.parse('{"msg":null,"data":{"partyBuildSummaryId":null,"completeNum":0,"itemBankNum":21,"articleNum":110,"avgStudyTime":0,"latestWeekData":[{"learningNum":0,"day":"04-02"},{"learningNum":0,"day":"04-03"},{"learningNum":0,"day":"04-04"},{"learningNum":0,"day":"04-05"},{"learningNum":0,"day":"04-06"},{"learningNum":0,"day":"04-07"},{"learningNum":0,"day":"04-08"}],"signUpNum":0,"eduName":"河北省石家庄市长安区教育局","eduId":"39fa5519dd23fc3be272caf9c6cc8801"},"status":200}');
      // data = data.data
      this.statistics1.forEach(item => {
        if (typeof data[item.code] !== 'undefined') {
          if (data[item.code]) item.count = data[item.code]
        }
        if (item.code === 'avgStudyTime') {
          if (item.count) item.count = format.timeStamp(parseInt(item.count) * 60)// 单位分钟
        }
      })
      this.statistics2.forEach(item => {
        if (typeof data[item.code] !== 'undefined') {
          item.count = data[item.code]
        }
      })
      const latestWeekData = data.latestWeekData || []
      this.latestWeekData = []
      latestWeekData.forEach(item => {
        this.latestWeekData.push({
          // value: (Math.random() * 100).toFixed(0),
          value: item.learningNum,
          name: item.day
        })
      })
      // console.log(JSON.stringify(this.latestWeekData))
    },

    //
    timeStamp(second_time) {
      var time = parseInt(second_time) + '秒'
      if (parseInt(second_time) > 60) {
        var second = parseInt(second_time) % 60
        var min = parseInt(second_time / 60)
        time = min + '分' + second + '秒'

        if (min > 60) {
          min = parseInt(second_time / 60) % 60
          var hour = parseInt(parseInt(second_time / 60) / 60)
          time = hour + '小时' + min + '分' + second + '秒'

          if (hour > 24) {
            hour = parseInt(parseInt(second_time / 60) / 60) % 24
            var day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24)
            time = day + '天' + hour + '小时' + min + '分' + second + '秒'
          }
        }
      }

      return time
    }

  }
}
</script>
